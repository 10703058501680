/* eslint-disable no-useless-escape */
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import axios from "axios";
import Notifications from "vue-notification";
import DatetimePicker from "vuetify-datetime-picker";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/src/locale/pt.ts";
import router from "./router";
import store from "./store";
import GmapVue from "gmap-vue";
import VuetifyMoney from "vuetify-money";
import "./utils";

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
axios.defaults.headers.common = {
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("tk")}`,
};

Vue.prototype.$http = axios;
Vue.router = router;
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(DatetimePicker);
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places",
  },
  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
});
Vue.use(VuetifyMoney);
Vue.prototype.$getDate = function (dateString) {
  if (!dateString) return "Data inválida/Não informada";
  var date = new Date(dateString);
  return date.toLocaleDateString("pt-BR", { timeZone: "UTC" });
};
Vue.prototype.$getDateTime = function (datetime) {
  let date = new Date(Date.parse(datetime));
  if (date && date?.toLocaleDateString() != "Invalid Date") {
    return `${date?.toLocaleDateString("pt-BR")} às ${date.toLocaleTimeString(
      "pt-BR",
      { timeStyle: "short" }
    )}`;
  }
  return "--";
};

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

function resetAxiosConfig() {
  axios.defaults.headers.common = {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("tk")}`,
  };
}

document.addEventListener(
  "visibilitychange",
  function () {
    if (document.hidden) {
      store.commit("setVisibility", 0);
    } else {
      store.commit("setVisibility", 1);
    }
  },
  false
);

Vue.prototype.$resetAxiosConfig = resetAxiosConfig;

Vue.prototype.$imgurl = function (v) {
  if (!v) return null;
  return `${process.env.VUE_APP_BASE_FILE_PATH}${v}`;
};

Vue.prototype.$getPrice = function (v) {
  if (!v) return "Sem Preço!";
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(v);
};

Vue.prototype.$activeTypes = [
  {
    value: 1,
    name: "Ativo",
  },
  {
    value: 0,
    name: "Desativado",
  },
];

Vue.prototype.$defaultAttrsDatetimePicker = {
  timePickerProps: {
    format: "24hr",
  },
  dateFormat: "dd/MM/yyyy",
  label: "Insira a Data",
  clearText: "Limpar Campo",
  okText: "Salvar",
};

Vue.prototype.$booleans = [
  {
    value: 1,
    name: "Sim",
  },
  {
    value: 0,
    name: "Não",
  },
];

Vue.prototype.$defaultAttributes = {
  outlined: true,
  clearable: true,
  required: true,
};

Vue.prototype.$moneyOptions = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  precision: 2,
};

Vue.prototype.$tinyMceUploadHandler = function (
  blobInfo,
  success,
  failure,
  progress
) {
  var xhr, formData;

  xhr = new XMLHttpRequest();
  // xhr.withCredentials = true;
  xhr.open("POST", axios.defaults.baseURL + "/upload");
  xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("tk")}`);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure("HTTP Error: " + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure("HTTP Error: " + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.path != "string") {
      failure("Invalid JSON: " + xhr.responseText);
      return;
    }

    success(json.path);
  };

  xhr.onerror = function () {
    failure(
      "Image upload failed due to a XHR Transport error. Code: " + xhr.status
    );
  };

  formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};

Vue.prototype.$openLink = function (url) {
  if (url) {
    window.open(url, "_blank");
  }
};

Vue.prototype.$parseDatetime = function (v, includeTime) {
  if (!v) return "Erro";
  if (!v.split) return "Erro";
  const [result, time] = v.split(" ");
  if (!result) return "Erro";
  let [year, month, date] = result.split("/");
  if (!year || !month || !date) {
    [year, month, date] = result.split("-");
  }
  if (!year || !month || !date) return "Erro";
  if (includeTime) {
    return `${date}/${month}/${year} às ${time}`;
  } else {
    return `${date}/${month}/${year}`;
  }
};

Vue.prototype.$breakReferences = function (v) {
  return JSON.parse(JSON.stringify(v));
};

Vue.prototype.$genders = [
  { value: 0, name: "Masculino" },
  { value: 1, name: "Feminino" },
  { value: 2, name: "Transgênero" },
  { value: 3, name: "Gênero neutro" },
  { value: 4, name: "Não-binário" },
  { value: 5, name: "Agênero" },
  { value: 6, name: "Pangênero" },
  { value: 7, name: "Genderqueer" },
  { value: 8, name: "Two-spirit" },
  { value: 9, name: "Terceiro gênero" },
  { value: 10, name: " Outros" },
  { value: 11, name: " Não desejo informar" },
];

Vue.prototype.$statusesBase = [
  {
    title: "Dados Incompletos",
    icon: "mdi-account-question",
    color: "#6F6F6F",
    status: 0,
    showOnAdminSelect: false,
  },
  {
    title: "Aguardando Análise",
    icon: "mdi-timer-outline",
    color: "#0d47a1",
    status: 1,
    showOnAdminSelect: true,
  },
  {
    title: "Aprovado",
    icon: "mdi-check",
    color: "#388e3c",
    status: 2,
    showOnAdminSelect: true,
  },
  {
    title: "Reanálise",
    icon: "mdi-reload",
    color: "#ef6c00",
    status: 3,
    showOnAdminSelect: true,
  },
  {
    title: "Corrigido: Aguardando Análise",
    icon: "mdi-timer-outline",
    color: "#A020F0",
    status: 4,
    showOnAdminSelect: false,
  },
  {
    title: "Reprovado",
    icon: "mdi-close",
    color: "#d32f2f",
    status: 5,
    showOnAdminSelect: true,
  },
];

Vue.prototype.$paymentStatuses = [
  {
    name: "Aguardando Pagamento",
    value: 0,
  },
  {
    name: "Pago",
    value: 1,
  },
  {
    name: "Vencido/Cancelado",
    value: 2,
  },
];

export default new Vuetify({
  // icons: {
  //     iconfont: "fa4",
  // },
  lang: {
    locales: { pt },
    current: "pt",
  },
});

Vue.use(Vuetify);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
  vuetify: new Vuetify(),
}).$mount("#app");
